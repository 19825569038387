/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Image, Text } from '@/components/atoms';
import styles from './index.module.scss';
import WinnerModal from './WinnerModal';

const ROLUETTE_TIME_SECONDS = 5;
const Lottery = ({ usersRegistereds, setUsersRegistereds }) => {
  const [rotateRoulette, setRotateRoulette] = useState(false);
  const [userWinner, setUserWinner] = useState('');
  const handleRotateRoulette = () => {
    setRotateRoulette((_prev) => !_prev);
    setTimeout(async () => {
      const winner =
        usersRegistereds.subscribers[
          Math.round(Math.random() * (usersRegistereds.subscribers.length - 1))
        ];
      setUserWinner(winner);
      setUsersRegistereds({
        subscribers: usersRegistereds.subscribers.filter(
          (user) => user !== winner
        )
      });
    }, ROLUETTE_TIME_SECONDS * 1000);
  };
  return (
    <div className={styles.roulette}>
      {userWinner && <WinnerModal winner={userWinner} />}
      <Text>SORTEO</Text>
      <Text>Live Shopping</Text>
      <div style={{ position: 'relative', width: 'fit-content' }}>
        <div style={{ width: 'fit-content' }}>
          <Image
            width={360}
            height={(461 / 390) * 360}
            src="/assets/ruleta_marco.svg"
          />
        </div>
        <Image
          imageStyles={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: 'fit-content',
            transition: `${ROLUETTE_TIME_SECONDS}s`,
            transform: rotateRoulette
              ? `translate(-50%,-50%) rotate(${3000 + Math.random() * 1000}deg)`
              : `translate(-50%,-50%) rotate(${0}deg)`
          }}
          width={280}
          height={280}
          src="/assets/ruleta_lineas.svg"
        />
        <div
          style={{
            position: 'absolute',
            top: 55,
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        >
          <Image
            width={32}
            height={32}
            src="/assets/ruleta_pitutito.svg"
          />
        </div>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
          onClick={!rotateRoulette ? handleRotateRoulette : null}
        >
          <Image
            width={350}
            height={(390 / 425) * 350}
            src="/assets/ruleta_centro.svg"
          />
        </div>
      </div>
    </div>
  );
};

Lottery.propTypes = {
  usersRegistereds: PropTypes.array.isRequired,
  setUsersRegistereds: PropTypes.func.isRequired
};
export default Lottery;
