import { useState, useRef, useEffect } from 'react';
import { SendMessageRequest } from 'amazon-ivs-chat-messaging';
import { IoChevronDown } from 'react-icons/io5';
import PropTypes from 'prop-types';
import { CiHeart } from 'react-icons/ci';
import { IoMdHeart } from 'react-icons/io';
import Message from './Message';
import { Input } from '@/components/atoms';
import Icon from '@/components/atoms/Icon';
import styles from './index.module.scss';
import getMessageWithoutUrl from '@/utils/getMessageWithoutUrl';

const ChatMobile = ({
  chatRoom,
  messages,
  setChatMobileOpen,
  chatMobileOpen,
  isChatConnected = false,
  streamer = false,
  moderator = false
}) => {
  const scrollRef = useRef(null);
  const [message, setMessage] = useState();
  const [isHeartAvailable, setIsHeartAvailable] = useState(true);

  useEffect(() => {
    const scroll = scrollRef.current;
    if (scroll) {
      const height = scroll.scrollHeight;
      scroll.scrollTo(0, height);
    }
  }, [messages]);
  const renderMessages = () => {
    return messages.map((messageInfo, i) => {
      if (messageInfo.type === 'SUCCESS') {
        return (
          <div
            style={{ flex: 'none' }}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            <p>{messageInfo.message}</p>
          </div>
        );
      }
      return (
        <Message
          message={messageInfo}
          moderator={moderator}
          chatRoom={chatRoom}
          index={i}
        />
      );
    });
  };
  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  // const handleError = (data) => {
  //   // const username = '';
  //   // const lastname = '';
  //   // const isModerator = 'false';
  //   // const email = '';
  //   // const userId = '';
  //   // const avatar = '';
  //   // const _message = `Error ${data.errorCode}: ${data.errorMessage}`;
  //   // const messageId = '';
  //   // const timestamp = `${Date.now()}`;
  //   // const newMessage = {
  //   //   type: 'ERROR',
  //   //   timestamp,
  //   //   username,
  //   //   lastname,
  //   //   isModerator,
  //   //   email,
  //   //   userId,
  //   //   avatar,
  //   //   _message,
  //   //   messageId
  //   // };
  //   // Mensaje de error
  //   // setMessages((prevState) => {
  //   //   return [...prevState, newMessage];
  //   // });
  // };

  const sendMessage = async (_message, isHeart) => {
    const { isUrl } = getMessageWithoutUrl(_message);
    if (isHeart) {
      const request = new SendMessageRequest('.', { heart: 'true' });
      try {
        await chatRoom.sendMessage(request);
      } catch (error) {
        // handleError(error);
      }
    } else if (!isUrl || (isUrl && (moderator || streamer))) {
      const content = `${_message.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}`;
      const request = new SendMessageRequest(content);
      try {
        await chatRoom.sendMessage(request);
      } catch (error) {
        // handleError(error);
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (message) {
        setTimeout(() => {
          if (!chatMobileOpen) setChatMobileOpen(true);
        }, 200);
        sendMessage(message);
        setMessage('');
      }
    }
  };
  const handleClickSendMessage = (e) => {
    if (message) {
      e.preventDefault();
      if (!chatMobileOpen) setChatMobileOpen(true);
      sendMessage(message);
      setMessage('');
    }
  };

  return (
    <div
      id="chat-container"
      className={styles.container}
    >
      <div
        style={{
          padding: '5px 15px',
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div
          className={styles.icon}
          style={{
            transition: '0.5s',
            transform: `rotate(${chatMobileOpen ? '0deg' : '180deg'})`
          }}
        >
          <IoChevronDown
            size={18}
            onClick={() => {
              setChatMobileOpen(!chatMobileOpen);
            }}
          />
        </div>
        <div
          className={`${styles.messages} ${
            chatMobileOpen ? styles.open : styles.close
          }`}
          ref={scrollRef}
        >
          {renderMessages()}
        </div>
        <div className={styles.input}>
          <Input
            enterKeyHint="send"
            id="chat-input"
            autoCompleteOff
            blurOnSubmit={false}
            name="message"
            onChange={handleChange}
            textColor="black"
            value={message}
            disabled={!isChatConnected}
            type="text"
            onKeyPress={handleKeyDown}
            placeholder="Escribir  aquí..."
          />
          <button
            className={styles.sendMessage}
            onClick={handleClickSendMessage}
          >
            <Icon
              type="sendMessage"
              color="white"
              fontSize={30}
            />
          </button>
          <button
            className={styles.heart}
            onClick={() => {
              if (isHeartAvailable) {
                setIsHeartAvailable(false);
                setTimeout(() => {
                  setIsHeartAvailable(true);
                }, 60000);
                sendMessage('', true);
              }
            }}
          >
            {isHeartAvailable ? (
              <CiHeart
                color="white"
                size={30}
              />
            ) : (
              <IoMdHeart
                size={30}
                color="#e74c3c"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default ChatMobile;

ChatMobile.propTypes = {
  chatRoom: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  isChatConnected: PropTypes.bool,
  moderator: PropTypes.bool,
  streamer: PropTypes.bool,
  setChatMobileOpen: PropTypes.func.isRequired,
  chatMobileOpen: PropTypes.bool.isRequired
};
