import { useState, useRef, useEffect } from 'react';
import { SendMessageRequest } from 'amazon-ivs-chat-messaging';
import PropTypes from 'prop-types';
import { CiHeart } from 'react-icons/ci';
import { IoMdHeart } from 'react-icons/io';
import Message from './Message';
import { Input } from '@/components/atoms';
import Icon from '@/components/atoms/Icon';
import styles from './index.module.scss';
import getMessageWithoutUrl from '@/utils/getMessageWithoutUrl';

const ChatDesktop = ({
  chatRoom,
  messages,
  isChatConnected = false,
  moderator = false,
  streamer = false,
  style = {}
}) => {
  const scrollRef = useRef(null);
  const [videoElement, setVideoElement] = useState(null);
  const [isHeartAvailable, setIsHeartAvailable] = useState(true);

  const [message, setMessage] = useState();
  useEffect(() => {
    setVideoElement(document.getElementById('video-player'));
  }, []);

  useEffect(() => {
    const scroll = scrollRef.current;
    if (scroll) {
      const height = scroll.scrollHeight;
      scroll.scrollTo(0, height);
    }
  }, [messages]);
  const renderMessages = () => {
    return messages.map((messageInfo, i) => {
      if (messageInfo.type === 'SUCCESS') {
        return (
          <div
            style={{ flex: 'none' }}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            <p>{messageInfo.message}</p>
          </div>
        );
      }
      return (
        <Message
          message={messageInfo}
          moderator={moderator}
          chatRoom={chatRoom}
          index={i}
        />
      );
    });
  };
  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  // const handleError = (data) => {
  //   // const username = '';
  //   // const lastname = '';
  //   // const isModerator = 'false';
  //   // const email = '';
  //   // const userId = '';
  //   // const avatar = '';
  //   // const _message = `Error ${data.errorCode}: ${data.errorMessage}`;
  //   // const messageId = '';
  //   // const timestamp = `${Date.now()}`;
  //   // const newMessage = {
  //   //   type: 'ERROR',
  //   //   timestamp,
  //   //   username,
  //   //   lastname,
  //   //   isModerator,
  //   //   email,
  //   //   userId,
  //   //   avatar,
  //   //   _message,
  //   //   messageId
  //   // };
  //   // Mensaje de error
  //   // setMessages((prevState) => {
  //   //   return [...prevState, newMessage];
  //   // });
  // };

  const sendMessage = async (_message, isHeart) => {
    const { isUrl } = getMessageWithoutUrl(_message);
    if (isHeart) {
      const request = new SendMessageRequest('.', { heart: 'true' });
      try {
        await chatRoom.sendMessage(request);
      } catch (error) {
        // handleError(error);
      }
    } else if (!isUrl || (isUrl && (moderator || streamer))) {
      const content = `${_message.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}`;
      const request = new SendMessageRequest(content);
      try {
        await chatRoom.sendMessage(request);
      } catch (error) {
        // handleError(error);
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.focus();
      if (message) {
        sendMessage(message);
        setMessage('');
      }
    }
  };
  const handleClickSendMessage = () => {
    if (message) {
      document.getElementById('chat-input')?.children[0]?.focus();
      sendMessage(message);
      setMessage('');
    }
  };
  return (
    <div
      id="chat-container"
      className={styles.container}
    >
      <h3 className={styles.title}>Chat</h3>

      <div
        style={{
          maxHeight: videoElement ? videoElement.scrollHeight - 100 : undefined,
          ...style
        }}
        className={styles.messages}
        ref={scrollRef}
      >
        {renderMessages()}
      </div>
      <div className={styles.input}>
        <Input
          name="message"
          enterKeyHint="send"
          autoCompleteOff
          onChange={handleChange}
          textColor="black"
          value={message}
          disabled={!isChatConnected}
          type="text"
          onKeyPress={handleKeyDown}
          placeholder="Escribir  aquí..."
        />
        <button
          style={style.sendMessage}
          className={styles.sendMessage}
          onClick={handleClickSendMessage}
        >
          <Icon
            type="sendMessage"
            color="white"
          />
        </button>
        <button
          className={styles.heart}
          onClick={() => {
            if (isHeartAvailable) {
              setIsHeartAvailable(false);
              setTimeout(() => {
                setIsHeartAvailable(true);
              }, 60000);
              sendMessage('', true);
            }
          }}
        >
          {isHeartAvailable ? (
            <CiHeart
              color="white"
              size={32}
            />
          ) : (
            <IoMdHeart
              size={32}
              color="#e74c3c"
            />
          )}
        </button>
      </div>
    </div>
  );
};
export default ChatDesktop;

ChatDesktop.propTypes = {
  chatRoom: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  isChatConnected: PropTypes.bool,
  moderator: PropTypes.bool,
  streamer: PropTypes.bool,
  style: PropTypes.object
};
