const credentials = {
  region: process.env.NEXT_PUBLIC_REGION,
  credentials: {
    accessKeyId: process.env.NEXT_PUBLIC_ACCESS_KEY_ID,
    secretAccessKey: process.env.NEXT_PUBLIC_SECRET_ACCESSKEY
  }
};
const response = {
  statusCode: 200,
  headers: {
    'Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST'
  },
  body: ''
};

/**
 * A function that generates an IVS chat authentication token based on the request parameters.
 */
const authChat = async (body) => {
  // Parse the incoming request body
  const { arn, roomIdentifier, userId } = body;
  const roomId = arn || roomIdentifier;
  const additionalAttributes = body.attributes || {};
  const capabilities = body.capabilities || []; // The permission to view messages is implicit
  const durationInMinutes = body.durationInMinutes || 55; // default the expiration to 55 mintues
  if (!roomId || !userId) {
    response.statusCode = 400;
    response.body = {
      error: 'Missing parameters: `arn or roomIdentifier`, `userId`'
    };
    return response;
  }
  // Construct parameters.
  // Documentation is available at https://docs.aws.amazon.com/ivs/latest/ChatAPIReference/Welcome.html
  const params = {
    roomIdentifier: `${roomId}`,
    userId: `${userId}`,
    attributes: { ...additionalAttributes },
    capabilities,
    sessionDurationInMinutes: durationInMinutes
  };
  try {
    const { Ivschat } = await import('@aws-sdk/client-ivschat');
    const IVSChat = new Ivschat(credentials);
    const data = await IVSChat.createChatToken(params);
    response.statusCode = 200;
    response.body = data;
  } catch (err) {
    response.statusCode = 500;
    response.body = err.stack;
  }

  return response;
};

// Events
const chatEventHandler = async (body) => {
  // Parse the incoming request body

  const { arn, eventAttributes, eventName } = body;

  // Construct parameters.
  // Documentation is available at https://docs.aws.amazon.com/ivs/latest/ChatAPIReference/Welcome.html
  const params = {
    roomIdentifier: `${arn}`,
    eventName,
    attributes: { ...eventAttributes }
  };

  try {
    const { Ivschat } = await import('@aws-sdk/client-ivschat');
    const IVSChat = new Ivschat(credentials);
    await IVSChat.sendEvent(params).promise();
    response.statusCode = 200;
    // If sendEvent() is successfull, it will return an empty response.
    // For the purposes of this API however, let's return "success" in the response body
    response.body = JSON.stringify({
      arn: `${arn}`,
      status: 'success'
    });
  } catch (err) {
    response.statusCode = 500;
    response.body = err.stack;
  }

  return response;
};
export default {
  authChat,
  chatEventHandler
};
