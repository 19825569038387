import PropTypes from 'prop-types';
import Button from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import Form from '@/components/organisms/Form';
import { Icon } from '@/components/atoms';
import styles from './index.module.scss';
import Text from '@/components/atoms/Text';

const SignInDesktop = ({ handleSignIn }) => {
  const onSubmit = async ({ firstname, lastname, email }) => {
    return handleSignIn({ firstname, lastname, email });
  };
  return (
    <div className={styles.container}>
      <Icon
        type="logoTransparent"
        color="var(--color-primary)"
        width="9rem"
      />
      <Text>Ingresa para continuar</Text>
      <Text>
        Ingresá tus datos para poder participar del sorteo y realizar tus
        compras.
      </Text>
      <Form
        className={styles.form}
        onSubmit={onSubmit}
      >
        <Input
          name="firstname"
          id="SigIn_ChatIvs"
          type="text"
          placeholder="Nombre..."
          autoComplete="off"
          required
          validation={{
            minLength: {
              value: 3,
              message: 'Debe tener al menos 3 caracteres'
            }
          }}
        />
        <Input
          name="lastname"
          id="SigIn_ChatIvs"
          type="text"
          placeholder="Apellido..."
          autoComplete="off"
          required
          validation={{
            minLength: {
              value: 3,
              message: 'Debe tener al menos 3 caracteres'
            }
          }}
        />
        <Input
          name="email"
          id="SigIn_ChatIvs"
          type="email"
          placeholder="Correo electrónico..."
          autoComplete="off"
          required
        />
        <Button
          submit
          variant="primary"
          label="Ingresar"
        />
      </Form>
    </div>
  );
};

export default SignInDesktop;

SignInDesktop.propTypes = { handleSignIn: PropTypes.func.isRequired };
