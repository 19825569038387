'use client';

/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-bitwise */

import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ChatRoom } from 'amazon-ivs-chat-messaging';

import { useSelector } from 'react-redux';

import dynamic from 'next/dynamic';
import apiLive from '@/api/ivs/live';
import ivs from '@/api/ivs/ivs';

import styles from './index.module.scss';
import useDeviceType from '@/hooks/useDeviceType';
import Spinner from '../Spinner';
import VideoPlayer from './VideoPlayer';
import Image from '@/components/atoms/Image';
import SignInMobile from './SignInMobile';
import ChatDesktop from './ChatDesktop';
import ChatMobile from './ChatMobile';
import SignInDesktop from './SignInDesktop';
import StreamerView from './StreamerView';
import Heart from './Heart';

const PromotedProductsDesktop = dynamic(
  () => import('./PromotedProductsDesktop'),
  { ssr: false }
);
const PromotedProductsMobile = dynamic(
  () => import('./PromotedProductsMobile'),
  { ssr: false }
);
// Chat websocket address
// The AWS region that your room is created in. For example, `us-west-2`.
const CHAT_REGION = process.env.NEXT_PUBLIC_REGION;
const COLORS = ['#2979ff', '#ff9100', '#ffea00', '#f50057'];
// Chat API URL
// The Amazon IVS Chat backend endpoint. You must deploy the serverless backend to get this value.

// Chat room id (ARN)
const CHAT_ROOM_ID = process.env.NEXT_PUBLIC_CHAT_ROOM_ID;

// Creates a UUID
const uuidv4 = () => {
  // eslint-disable-next-line
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// Escape special characters
const sanitize = (string) => {
  function concatRegexp(reg1, reg2) {
    return new RegExp(reg1.source + reg2.source, 'g');
  }
  const wordBlockeds = [
    'puto',
    'puta',
    'trolo',
    'trola',
    'homosexual',
    'travesti',
    'petero',
    'petera',
    'culiado',
    'culeado',
    'culiao',
    'hdp',
    'pija',
    'pene',
    'pito',
    'concha',
    'poronga',
    'orto',
    'culo',
    'mierda',
    'basura',
    'caca',
    'cagada',
    'bosta',
    'ano',
    'pelotudo',
    'pelotuda',
    'boludo',
    'boluda',
    'otario',
    'choto',
    'chota'
  ];
  const map = {
    '<': '',
    '>': '',
    '"': '',
    "'": '',
    '`': ''
  };

  const reg = concatRegexp(new RegExp(`${wordBlockeds.join('|')}|`), /[&<>"']/);
  return string
    .toLowerCase()
    .replace(reg, (match) => (map[match] !== undefined ? map[match] : '***'));
};
const Streaming = () => {
  const [showSignIn, setShowSignIn] = useState(true);
  const [moderator, setModerator] = useState(false);
  const [streamer, setStreamer] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatMobileOpen, setChatMobileOpen] = useState(true);
  const [chatRoom, setChatRoom] = useState([]);
  const [chatRoomConnected, setChatRoomConnected] = useState(false);
  const [accessToChat, setAccessToChat] = useState(true);
  const [showHeart, setShowHeart] = useState(false);
  const {
    highlighted: highlightedProductUrl,
    finishedCountdown,
    viewers: actualViewers,
    productsInMetaData
  } = useSelector(({ streaming }) => streaming);
  const { isDesktop, isMobile } = useDeviceType();

  const userLogged = useSelector(({ checkout }) => checkout.personalInfo) || {};
  const tokenProvider = async (_user, isModerator, isSuperModerator) => {
    const { firstname, lastname, email } = _user;
    const uuid = uuidv4();
    const permissions = isModerator
      ? ['SEND_MESSAGE', 'DELETE_MESSAGE', 'DISCONNECT_USER']
      : ['SEND_MESSAGE'];

    const data = {
      arn: CHAT_ROOM_ID,
      userId: `${firstname}.${uuid}`,
      attributes: {
        username: `${firstname}`,
        lastname,
        email,
        isModerator: `${isModerator}`,
        isSuperModerator: `${isSuperModerator}`,
        avatar: COLORS[Math.floor(Math.random() * COLORS.length)]
      },
      capabilities: permissions
    };

    let token;
    try {
      const response = await ivs.authChat(data);
      token = {
        token: response.body.token,
        sessionExpirationTime: new Date(response.body.sessionExpirationTime),
        tokenExpirationTime: new Date(response.body.tokenExpirationTime)
      };

      // eslint-disable-next-line no-empty
    } catch (error) {}

    return token;
  };
  const handleSignIn = async (_user) => {
    const userInfo = {
      firstname: _user.first_name || _user.firstname,
      lastname: _user.last_name || _user.lastname,
      email: _user.email
    };

    const data = await apiLive.isBanned(_user.email);
    const { banned } = data;
    const live = await apiLive.saveUserInLottery({
      subscriber: userInfo
    });
    if (userLogged.email) setModerator(live?.isModerator);
    if (userLogged.email && live.isSuperModerator) setStreamer(true);
    // eslint-disable-next-line no-shadow
    if (!banned && live) {
      const room = new ChatRoom({
        regionOrUrl: CHAT_REGION,
        tokenProvider: () =>
          tokenProvider(_user, live?.isModerator, live?.isSuperModerator)
      });
      setChatRoom(room);

      room.connect();
    } else {
      setAccessToChat(false);
    }
  };
  useEffect(() => {
    (async () => {
      const userInfo = {
        firstname: userLogged.first_name,
        lastname: userLogged.last_name,
        email: userLogged.email
      };
      if (userInfo.email && chatRoom._state !== 'connected') {
        handleSignIn(userInfo);
      }
    })();
  }, [userLogged]);

  const renderDisconnect = (userId) => {
    setMessages((prevState) => {
      return prevState.filter((m) => m.userId !== userId);
    });
  };
  const handleMessage = (data) => {
    const { username, lastname, avatar, email, isModerator, isSuperModerator } =
      data.sender.attributes;
    const { heart } = data.attributes || {};

    if (heart && JSON.parse(heart)) {
      // Obtener el contenedor padre
      const chatContainer = document.getElementById('chat-container');

      // Crear un nuevo elemento div y establecer su id
      const element = document.createElement('div');

      // Renderizar el componente React y agregar el nodo al elemento div
      ReactDOM.render(<Heart />, element);

      // Agregar el nuevo elemento como hijo al contenedor
      chatContainer.appendChild(element);
      setTimeout(() => {
        chatContainer.removeChild(element);
      }, 2000);
      return;
    }
    const { userId } = data.sender;
    const message = sanitize(data.content);
    const messageId = data.id;
    const timestamp = data.sendTime;
    const newMessage = {
      type: 'MESSAGE',
      timestamp,
      username,
      lastname,
      email,
      isModerator,
      isSuperModerator,
      userId,
      avatar,
      message,
      messageId
    };

    setMessages((prevState) => {
      return [...prevState, newMessage];
    });
  };
  const handleEvent = (event) => {
    const { eventName } = event;
    switch (eventName) {
      case 'aws:DELETE_MESSAGE':
        // Ignore system delete message events, as they are handled
        // by the messageDelete listener on the room.
        break;
      case 'app:DELETE_BY_USER':
        setMessages((prevState) => {
          // Remove message that matches the MessageID to delete
          const newState = prevState.filter(
            (item) => item.userId !== event.attributes.userId
          );
          return newState;
        });
        break;
      default:
    }
  };

  useEffect(() => {
    if (!chatRoom.addListener) {
      return;
    }

    setShowSignIn(false);

    // const unsubscribeOnConnected = chatRoom.addListener('connect', () => {
    //   // Connected to the chat room.
    // });

    const unsubscribeOnUserDisconnect = chatRoom.addListener(
      'userDisconnect',
      ({ userId }) => {
        renderDisconnect(userId);
      }
    );

    const unsubscribeOnMessageReceived = chatRoom.addListener(
      'message',
      (message) => {
        handleMessage(message);
      }
    );

    const unsubscribeOnEventReceived = chatRoom.addListener(
      'event',
      (event) => {
        // Received an event
        handleEvent(event);
      }
    );
    const unsubscribeOnConnected = chatRoom.addListener('connect', () => {
      setChatRoomConnected(true);
    });
    const unsubscribeOnMessageDeleted = chatRoom.addListener(
      'messageDelete',
      (deleteEvent) => {
        // Received message delete event
        const messageIdToDelete = deleteEvent.messageId;
        setMessages((prevState) => {
          // Remove message that matches the MessageID to delete
          const newState = prevState.filter(
            (item) => item.messageId !== messageIdToDelete
          );
          return newState;
        });
      }
    );

    // eslint-disable-next-line consistent-return
    return () => {
      // unsubscribeOnConnected();
      unsubscribeOnUserDisconnect();
      unsubscribeOnConnected();
      unsubscribeOnMessageReceived();
      unsubscribeOnEventReceived();
      unsubscribeOnMessageDeleted();
    };
  }, [chatRoom]);

  const { hasVideo, error } = useSelector(({ streaming }) => streaming);

  useEffect(() => {
    // Se debe poner en un timeout, el scroll debe hacerse luego de que se renderize el nuevo DOM
    if (hasVideo && !error) {
      setTimeout(() => {
        window.scrollTo({
          top: isMobile ? 380 : 200,
          left: 0,
          behavior: 'smooth'
        });
      }, 500);
    }
  }, [isMobile, chatRoom]);
  return (
    <div className={streamer ? styles.streamerContainer : styles.container}>
      {!streamer ? (
        <>
          {isDesktop && (
            <Image
              className={styles.image}
              src="/assets/backgroundLive.png"
              fill
            />
          )}

          {isDesktop && !streamer && !isMobile && (
            <PromotedProductsDesktop
              finishedCountdown={finishedCountdown}
              productsInMetaData={productsInMetaData}
              highlightedProductUrl={highlightedProductUrl}
            />
          )}
          <VideoPlayer actualViewers={actualViewers} />
          {chatRoomConnected && isDesktop ? (
            <ChatDesktop
              chatRoom={chatRoom}
              messages={messages}
              isChatConnected={chatRoomConnected}
              actualViewers={actualViewers}
              streamer={streamer}
              moderator={moderator}
              showHeart={showHeart}
            />
          ) : (
            chatRoomConnected && (
              <ChatMobile
                chatRoom={chatRoom}
                messages={messages}
                isChatConnected={chatRoomConnected}
                actualViewers={actualViewers}
                streamer={streamer}
                moderator={moderator}
                setChatMobileOpen={setChatMobileOpen}
                chatMobileOpen={chatMobileOpen}
                showHeart={showHeart}
              />
            )
          )}
          {!isDesktop && (
            <PromotedProductsMobile
              finishedCountdown={finishedCountdown}
              productsInMetaData={productsInMetaData}
              highlightedProductUrl={highlightedProductUrl}
              chatMobileOpen={chatMobileOpen}
            />
          )}
          {chatRoom._state === 'connecting' && (
            <div>
              <Spinner isLarge />
            </div>
          )}
          {(chatRoom._state === 'disconnected' || !accessToChat) && (
            <div>
              <p style={{ color: 'white' }}>Has sido expulsado del chat.</p>
            </div>
          )}
          {showSignIn && accessToChat && isDesktop && (
            <SignInDesktop handleSignIn={handleSignIn} />
          )}
          {showSignIn && accessToChat && !isDesktop && (
            <SignInMobile handleSignIn={handleSignIn} />
          )}
        </>
      ) : (
        <StreamerView
          chatRoom={chatRoom}
          messages={messages}
          isChatConnected={chatRoomConnected}
          actualViewers={actualViewers}
          streamer={streamer}
          moderator={moderator}
        />
      )}
    </div>
  );
};

export default Streaming;
