import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FiEye } from 'react-icons/fi';
import apiLive from '@/api/ivs/live';
import styles from './index.module.scss';
import { Image } from '@/components/atoms';

const StreamingDecorations = ({ actualViewers }) => {
  const [viewers, setViewers] = useState(actualViewers);
  const getViewers = async () => {
    const live = await apiLive.getInfo();
    if (live.hasError) {
      setViewers(0);
    } else {
      setViewers(live.viewers);
    }
  };
  useEffect(() => {
    getViewers();
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => getViewers(), 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className={styles.viewers}>
        <FiEye fontSize={24} />
        <span>{viewers}</span>
      </div>
      <div className={styles.liveLogo}>
        <Image
          width={60}
          height={30}
          src="/logos/live-logo.svg"
          alt="Live logo"
        />
      </div>
    </>
  );
};

export default StreamingDecorations;

StreamingDecorations.propTypes = { actualViewers: PropTypes.number.isRequired };
