import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/atoms/Button';
import ChatDesktop from '../ChatDesktop';
import styles from './index.module.scss';
import Lottery from './Lottery';
import api from '@/api/ivs/live';

const StreamerView = ({
  chatRoom,
  messages,
  isChatConnected,
  actualViewers,
  streamer,
  moderator
}) => {
  const [lottery, setLottery] = useState(false);
  const [usersRegistereds, setUsersRegistereds] = useState({ subscribers: [] });
  useEffect(() => {
    (async () => {
      const subscribers = await api.getUsersInLottery();
      if (subscribers.success) {
        setUsersRegistereds(subscribers.data);
      }
    })();
  }, []);
  return (
    <div className={styles.container}>
      {!lottery ? (
        <ChatDesktop
          style={{
            height: '86vh',
            sendMessage: { right: '65px' },
            fontSize: '30px'
          }}
          chatRoom={chatRoom}
          messages={messages}
          isChatConnected={isChatConnected}
          actualViewers={actualViewers}
          streamer={streamer}
          moderator={moderator}
        />
      ) : (
        <Lottery
          usersRegistereds={usersRegistereds}
          setUsersRegistereds={setUsersRegistereds}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          style={{ maxWidth: '500px' }}
          label={
            // eslint-disable-next-line no-nested-ternary
            lottery
              ? 'Volver al chat'
              : usersRegistereds.subscribers.length > 0
              ? 'Hacer sorteo'
              : 'No hay usuarios inscriptos'
          }
          variant="primary"
          onClick={() => {
            if (!lottery && usersRegistereds.subscribers.length > 0) {
              setLottery(!lottery);
            } else if (lottery) {
              setLottery(!lottery);
            }
          }}
        />
      </div>
    </div>
  );
};
StreamerView.propTypes = {
  chatRoom: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  isChatConnected: PropTypes.bool.isRequired,
  actualViewers: PropTypes.number.isRequired,
  streamer: PropTypes.bool.isRequired,
  moderator: PropTypes.bool.isRequired
};
export default StreamerView;
