import PropTypes from 'prop-types';
import { useState } from 'react';
import Button from '@/components/atoms/Button';
import styles from './index.module.scss';
import SignInDesktop from '../SignInDesktop';
import { GeneralModal } from '@/components/molecules';

const SignInMobile = ({ handleSignIn }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        label="Ingresar al live"
        variant="primary"
      />
      {open && (
        <GeneralModal
          withoutCloseIcon
          onClose={() => setOpen(false)}
        >
          <div className={styles.modal}>
            <SignInDesktop handleSignIn={handleSignIn} />
          </div>
        </GeneralModal>
      )}
    </>
  );
};

export default SignInMobile;

SignInMobile.propTypes = { handleSignIn: PropTypes.func.isRequired };
