import { useEffect, useRef } from 'react';
import JSConfetti from 'js-confetti';
import PropTypes from 'prop-types';
import { Image, Text } from '@/components/atoms';
import styles from './index.module.scss';

const WinnerModal = ({ winner }) => {
  const winnerRef = useRef(null);
  useEffect(() => {
    let intervalConfettis;
    if (winnerRef.current) {
      const confetti = new JSConfetti(winnerRef.current);
      confetti?.addConfetti({
        confettiColors: ['#ffbe1e'],
        confettiRadius: 4,
        confettiNumber: 400
      });
      intervalConfettis = setInterval(() => {
        confetti?.addConfetti({
          confettiColors: ['#ffbe1e'],
          confettiRadius: 4,
          confettiNumber: 400
        });
      }, 4000);
    } else {
      clearInterval(intervalConfettis);
    }
    return () => {
      clearInterval(intervalConfettis);
    };
  }, [winnerRef.current]);
  return (
    <div
      ref={winnerRef}
      className={styles.winner}
    >
      <div className={styles.message}>
        <Image
          width={330}
          height={330}
          src="/assets/ruleta_ganador.svg"
        />
        <Text
          textStyle={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-68%)',
            color: 'black'
          }}
        >
          {winner}
        </Text>
      </div>
    </div>
  );
};

WinnerModal.propTypes = { winner: PropTypes.string.isRequired };
export default WinnerModal;
