/* eslint-disable prefer-destructuring */
const getMessageWithoutUrl = (userInput) => {
  const urlRegExp = /(https?:\/\/\S+)/;
  const urlArray = userInput?.split(urlRegExp) || [];
  let url;
  let messageWithoutUrl;
  // Si en la posición primera existe algo, significa que la url viene despues del mensaje
  if (urlArray[0]) {
    url = urlArray[1];
    messageWithoutUrl = urlArray[0];
  } else {
    url = urlArray[1];
    messageWithoutUrl = urlArray[2];
  }
  return { isUrl: urlArray.length > 1, url, messageWithoutUrl };
};
export default getMessageWithoutUrl;
