/* eslint-disable prefer-destructuring */
import { useState } from 'react';
import PropTypes from 'prop-types';
// import cn from 'classnames';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { BsFillTrashFill } from 'react-icons/bs';
import {
  DeleteMessageRequest,
  DisconnectUserRequest
} from 'amazon-ivs-chat-messaging';
import { MdPersonOff } from 'react-icons/md';
import apiLive from '@/api/ivs/live';
import apiIvs from '@/api/ivs/ivs';
import Avatar from '../Avatar';
import styles from './index.module.scss';

const CHAT_ROOM_ID = process.env.NEXT_PUBLIC_CHAT_ROOM_ID;

const ModeratorBtn = ({ message, chatRoom, isModerator = false }) => {
  const [openModerator, setOpenModerator] = useState(false);
  const sendEvent = async (data) => {
    const formattedData = {
      arn: CHAT_ROOM_ID,
      eventName: `${data.eventName}`,
      eventAttributes: data.eventAttributes
    };

    try {
      const response = await apiIvs.chatEventHandler(formattedData);
      return response;
    } catch (error) {
      return error;
    }
  };
  const handleMessageDelete = async (messageId) => {
    const request = new DeleteMessageRequest(
      messageId,
      'Mensaje borrado por un moderador'
    );
    try {
      await chatRoom.deleteMessage(request);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };
  const deleteMessageByUserId = async (userId) => {
    try {
      const response = await sendEvent({
        eventName: 'app:DELETE_BY_USER',
        eventAttributes: {
          userId
        }
      });
      return response;
    } catch (error) {
      return error;
    }
  };

  const handleUserKick = async ({ userId, email }) => {
    const request = new DisconnectUserRequest(
      userId,
      'Expulsado por un moderador.'
    );
    try {
      await chatRoom.disconnectUser(request);
      await deleteMessageByUserId(userId);

      apiLive.banUserFromChat(email);
    } catch (error) {
      return 0;
    }
  };

  return (
    <div className={styles.moderatorBtns}>
      <div
        className={`${styles.options} ${
          openModerator ? styles.showOptions : ''
        }`}
      >
        <BsFillTrashFill
          onClick={() => handleMessageDelete(message.messageId)}
          fontSize={18}
        />
        {!isModerator && (
          <MdPersonOff
            onClick={() => handleUserKick(message)}
            fontSize={20}
          />
        )}
      </div>
      <BiDotsVerticalRounded
        fontSize={20}
        onClick={() => setOpenModerator(!openModerator)}
      />
    </div>
  );
};

const Message = ({ message, index, chatRoom, moderator = false }) => {
  const isModeratorMessage = message.isModerator === 'true';
  const isSuperModeratorMessage = message.isSuperModerator === 'true';
  const getMessageWithoutUrl = (userInput) => {
    const urlRegExp = /(https?:\/\/\S+)/;
    const urlArray = userInput?.split(urlRegExp) || [];
    let url;
    let messageWithoutUrl;
    // Si en la posición primera existe algo, significa que la url viene despues del mensaje
    if (urlArray[0]) {
      url = urlArray[1];
      messageWithoutUrl = urlArray[0];
    } else {
      url = urlArray[1];
      messageWithoutUrl = urlArray[2];
    }
    return { isUrl: urlArray.length > 1, url, messageWithoutUrl };
  };

  const { url, messageWithoutUrl, isUrl } = getMessageWithoutUrl(
    message.message
  );
  return (
    <div
      className={styles.message}
      key={index}
    >
      <Avatar
        isSuperModerator={isSuperModeratorMessage}
        isModerator={isModeratorMessage}
        color={message.avatar}
        username={message.username}
      />
      <div style={{ wordBreak: 'break-all' }}>
        <p>
          <span
            style={{ textTransform: 'capitalize' }}
          >{`${message.username}: `}</span>
          <span style={{ fontWeight: '300' }}>
            {messageWithoutUrl}
            {isUrl ? (
              <a
                style={{ color: '#00ccff' }}
                rel="noreferrer"
                href={url}
                target="_blank"
              >
                {url}
              </a>
            ) : null}
          </span>
        </p>
      </div>
      {moderator ? (
        <ModeratorBtn
          isModerator={isModeratorMessage}
          chatRoom={chatRoom}
          message={message}
        />
      ) : (
        ''
      )}
    </div>
  );
};
export default Message;

Message.propTypes = {
  message: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  moderator: PropTypes.bool,
  chatRoom: PropTypes.object.isRequired
};

ModeratorBtn.propTypes = {
  message: PropTypes.string.isRequired,
  isModerator: PropTypes.bool,
  chatRoom: PropTypes.object.isRequired
};
