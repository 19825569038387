import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Image } from '@/components/atoms';

const Avatar = ({
  username,
  color,
  isModerator = false,
  isSuperModerator = false
}) => {
  const moderatorLogic = () => {
    if (isSuperModerator)
      return (
        <Image
          width={23}
          height={22}
          src="/assets/crown_icon.svg"
        />
      );
    if (isModerator)
      return (
        <Image
          width={23}
          height={22}
          src="/favicon.jpg"
        />
      );
    return username[0]?.toUpperCase();
  };

  return (
    <div
      className={styles.avatar}
      style={{
        backgroundColor: isModerator ? 'black' : color
      }}
    >
      {moderatorLogic()}
    </div>
  );
};

export default Avatar;

Avatar.propTypes = {
  username: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isModerator: PropTypes.bool,
  isSuperModerator: PropTypes.bool
};
