import { FaHeart } from 'react-icons/fa';
import styles from './index.module.scss';

const Heart = () => {
  return (
    <>
      <div
        style={{ position: 'absolute', left: `${Math.random() * 80}vw` }}
        className={styles.heart1}
      >
        <FaHeart size={20} />
      </div>
      <div
        style={{ position: 'absolute', left: `${Math.random() * 80}vw` }}
        className={styles.heart2}
      >
        <FaHeart size={20} />
      </div>
      <div
        style={{ position: 'absolute', left: `${Math.random() * 80}vw` }}
        className={styles.heart3}
      >
        <FaHeart size={20} />
      </div>
      <div
        style={{ position: 'absolute', left: `${Math.random() * 80}vw` }}
        className={styles.heart4}
      >
        <FaHeart size={20} />
      </div>
      <div
        style={{ position: 'absolute', left: `${Math.random() * 80}vw` }}
        className={styles.heart5}
      >
        <FaHeart size={20} />
      </div>
    </>
  );
};

export default Heart;
