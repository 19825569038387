/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './VideoPlayer.module.scss';
import {
  addProductInMetaData,
  finishedCountdown,
  highlightedProduct,
  stateIvs
} from '@/redux/streaming';
import StreamingDecorations from '../StreamingDecorations';

const videoUrl = process.env.NEXT_PUBLIC_DEFAULT_VIDEO_STREAM;
const VideoPlayer = ({ actualViewers }) => {
  const dispatch = useDispatch();

  const mediaPlayerScriptLoaded = () => {
    try {
      const MediaPlayerPackage = window.IVSPlayer;

      if (!MediaPlayerPackage.isPlayerSupported) {
        return;
      }

      const { PlayerState } = MediaPlayerPackage;
      const { PlayerEventType } = MediaPlayerPackage;

      // Initialize player
      const player = MediaPlayerPackage.create();
      player.attachHTMLVideoElement(document.getElementById('video-player'));

      player.addEventListener(PlayerState.ENDED, () => {
        if (window.ivsHasVideo) {
          window.ivsHasVideo = false;
          dispatch(stateIvs({ hasVideo: false }));
        }
      });
      player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, (cue) => {
        const metadataText = JSON.parse(cue.text);
        const { productUrl, finishCountdown, addProduct } = metadataText;
        if (productUrl) {
          dispatch(highlightedProduct(productUrl));
        }
        if (addProduct) {
          dispatch(addProductInMetaData(addProduct));
        }
        if (finishCountdown === false || finishCountdown === true) {
          dispatch(finishedCountdown(finishCountdown));
        }
      });
      player.addEventListener(PlayerState.READY, () => {
        if (!window.ivsHasVideo || window.ivsHasError) {
          window.ivsHasVideo = true;
          window.ivsHasError = false;
          dispatch(stateIvs({ hasVideo: true, error: false }));
        }
      });
      player.addEventListener(PlayerEventType.ERROR, () => {
        if (!window.ivsHasError) {
          window.ivsHasError = true;
          dispatch(stateIvs({ error: true }));
        }
      });
      player.load(videoUrl);
      document
        .getElementById('video-container')
        .addEventListener('click', () => player.setMuted(false));
      player.play();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };
  const [userClickedSound, setUserClickedSound] = useState(false);
  useEffect(() => {
    const mediaPlayerScript = document.createElement('script');
    mediaPlayerScript.src =
      'https://player.live-video.net/1.12.0/amazon-ivs-player.min.js';
    mediaPlayerScript.async = true;
    mediaPlayerScript.onload = () => mediaPlayerScriptLoaded();
    document.body.appendChild(mediaPlayerScript);
  }, []);
  return (
    <div
      id="video-container"
      className={styles.video}
    >
      <video
        controlsList="nofullscreen"
        playsInline
        autoPlay
        preload="auto"
        id="video-player"
      />
      {!userClickedSound && (
        <div
          className={styles.activateSound}
          onKeyDown={() => {}}
          role="button"
          tabIndex="0"
          onClick={() => {
            setUserClickedSound(true);
          }}
        >
          Clic para activar sonido
        </div>
      )}
      <StreamingDecorations actualViewers={actualViewers} />
    </div>
  );
};

VideoPlayer.propTypes = {
  actualViewers: PropTypes.number.isRequired
};
export default VideoPlayer;
